<template>
    <div class="underline">
        <a class="cursor-pointer" @click="open()">{{ $t('modal.termsandcondition.button.open') }}</a>
    </div>

    <transition name="blur-background">
        <blur v-if="show">
        </blur>
    </transition>

    <transition name="modal-small">
        <div class="fixed w-full inset-0 overflow-y-auto ccss--modal-terms-conditions fixed-modal-wrapper" v-if="show">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <div
                    class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full radius-anchor"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                >
                    <div class="p-4 pb-0">
                        <h3 class="mb-3 text-2xl" id="modal-headline">
                            {{ $t('modal.termsandcondition.title') }}
                        </h3>
                        <div class="modal-content-shrink">
                            <!-- //CONTENT HERE-->

                        </div>
                    </div>
                    <div class="p-4">
                        <button type="button" @click="close()"
                                class="btn btn-close w-full"
                        >
                            {{ $t('button.close') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>

import Blur from "./Blur";

export default {
    components: {
        Blur,
    },
    data () {
        return {
            show: false,
        };
    },
    methods: {
        open () {
            this.show = true;
        },
        close () {
            this.show = false;
        },
    },
    computed: {
        location_slug () {
            return this.$route.params.location_slug;
        },
    },
};
</script>
