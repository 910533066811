<template>
    <div class="sm:flex sm:justify-center ccss--reservation">
        <div class="sm:w-1/2 content-container border p-10 checkout-box radius-anchor">
            <div class="text-center mb-4">
                <h2 class="font-bold text-2xl text-center">{{ $t('reservation.title') }}</h2>
                <a v-if="page == 'personalinformation'" @click="gotoTimeslot(), tableStillAvailable = true" class="cursor-pointer underline text-sm text-center">{{
                    $t('reservation.goback')
                }}</a>
            </div>
            <!-- timeslot -->
            <div v-if="page == 'timeslot'">
                <div class="mt-5 mb-3">
                    <div class="my-2">
                        <!--                            {{form.date}}-->
                        <v-date-picker
                            @dayclick="setTimeslots()"
                            class="w-full"
                            :disabled-dates="timeslot.attributes?.unavailable_dates"
                            :min-date="minimum_date"
                            :max-date="timeslot.attributes?.maximum_date"
                            v-model="form.date"
                            :attributes="datepicker_attributes"
                        />
                    </div>

                    <!-- area selection -->
                    <div v-if="settings.areaselection == true" class="mt-5 sm:col-span-1">
                        <Field type="hidden" v-model="form.area_id" name="area_id" />

                        <label for="area_id" class="block text-base mb-2 font-bold">{{ $t('label.select') }} Area *</label>
                        <div v-if="areas.length == 0" class="mt-1 rounded-sm p-4 mb-5 alert-warning text-center">
                            {{ $t('reservation.areas.unavailable') }}
                        </div>
                        <div v-else>
                            <div v-for="(area, index) in areas" :key="index">
                                <div
                                    @click="selectArea(area)"
                                    class="mb-2 font-bold border px-4 py-3 cursor-pointer area-option radius-anchor"
                                    :class="{ active: area.id == form.area_id }"
                                >
                                    <div>{{ area.attributes.name }}</div>
                                    <div class="italic">{{ area.attributes.description }}</div>
                                </div>

                                <!-- todo: add area image -->
                                <transition name="accordion">
                                    <img
                                        v-if="area.id == form.area_id && area.attributes.image"
                                        class="area-image radius-anchor"
                                        :src="area.attributes.image"
                                    />
                                </transition>
                            </div>
                        </div>
                    </div>

                    <!-- quantity -->
                    <div
                        class="mt-5 mb-3"
                        v-if="(settings.areaselection == false || form.area_id) && timeslot.attributes?.group_size_mode != 'no_group_size'"
                    >
                        <label for="quantity" class="block text-base mb-2 font-bold">{{ $t('fields.number_of_persons') }} *</label>
                        <div class="mt-1">
                            <Field
                                as="select"
                                @change="setTimeslots()"
                                v-model="form.quantity"
                                name="quantity"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                            >
                                <option
                                    v-for="quantity in (timeslot.attributes?.resources_range_from, timeslot.attributes?.resources_range_to)"
                                    :value="quantity"
                                    :key="quantity"
                                >
                                    {{ quantity }}
                                </option>
                            </Field>
                        </div>
                    </div>

                    <!-- timeslot selection -->
                    <div class="mt-4 sm:col-span-1" v-if="settings.areaselection == false || form.area_id">
                        <Field
                            type="hidden"
                            v-model="form.timeslot"
                            name="timeslot"
                            class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                        />

                        <div class="" v-if="timeslot.attributes && timeslot.attributes.has_ending_time == true">
                            <label class="block text-base font-bold"> Kies aankomst- en vertrektijd </label>
                            <div
                                v-if="fullDayAvailable"
                                class="relative flex items-center h-5 mt-4 mb-2 inline-block cursor-pointer"
                                @click="toggleSelectFullDay()"
                            >
                                <input type="checkbox" class="mr-2 cursor-pointer" :checked="full_day_checked" />
                                <label class="cursor-pointer block text-base font-bold"> Selecteer de hele dag </label>
                            </div>
                            <div class="flex gap-4 w-full" v-if="full_day_checked === false">
                                <select
                                    v-if="timeslot.attributes?.timeslots.length > 0"
                                    @change="processTimeslotsAvailableAfterStart()"
                                    v-model="reservation_start_time"
                                    class="mt-1 flex-grow radius-anchor select-start"
                                >
                                    <option value=""></option>
                                    <template v-for="(timeslot, index) in timeslot.attributes.timeslots" :key="index">
                                        <option v-if="timeslot.available">
                                            {{ timeslot.from }}
                                        </option>
                                    </template>
                                </select>

                                <select
                                    v-if="timeslot.attributes?.timeslots.length > 0"
                                    v-model="reservation_end_time"
                                    @change="processTimeslotsAvailableAfterStart()"
                                    class="mt-1 flex-grow radius-anchor select-end"
                                >
                                    <option value=""></option>
                                    <template v-for="(timeslot, index) in timeslotsAvailableAfterStart" :key="index">
                                        <option v-if="timeslot.available">
                                            {{ timeslot.to }}
                                        </option>
                                    </template>
                                </select>
                            </div>
                        </div>

                        <div v-else>
                            <label for="timeslot" class="block text-base mb-2 font-bold"
                                >{{ $t('label.select') }} {{ $t('fields.timeslot') }} *</label
                            >

                            <div v-if="timeslot.attributes?.timeslots.length > 0" class="mt-1 grid grid-cols-2 gap-2">
                                <template v-for="(timeslot, index) in timeslot.attributes?.timeslots" :key="index">
                                    <div
                                        v-if="timeslot.available"
                                        @click="combineTimeslotsAndReservationItems(timeslot)"
                                        class="btn btn-small checkout-option"
                                        :class="{ active: timeslot.slot == form.timeslot }"
                                    >
                                        {{ timeslot.label }}
                                    </div>
                                    <div v-else class="btn btn-small checkout-option line-through text-muted opacity-80">
                                        {{ timeslot.label }}
                                    </div>
                                </template>
                            </div>

                            <div v-else>
                                {{ $t('timeslot.selection.unavailable') }}
                            </div>
                        </div>
                    </div>

                    <div
                        class="mt-5 mb-3"
                        v-if="form.timeslot?.length && tables && tables.data?.length > 0 && timeslot.attributes.reserve_table == true"
                    >
                        <label for="table" class="block text-base mb-2 font-bold"
                            >{{ $t('fields.table') }} *
                            <div v-if="!tablesAvailable(tables.data)" class="text-red-500">
                                {{ $t('reservation.no_tables_available') }}
                            </div>
                        </label>
                        <div class="mt-1">
                            <Field
                                as="select"
                                @change="setTable($event)"
                                name="table"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                            >
                                <option v-for="(table, index) in tables.data" :value="table.id" :key="index" :disabled="table.available == false">
                                    {{ table.name }} <span v-if="!table.available"> --- Niet beschikbaar</span>
                                </option>
                            </Field>
                        </div>
                    </div>
                </div>

                <div class="w-full mt-5 relative">
                    <!-- <button :disabled="" type="submit" class="w-full inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"> -->
                    <div
                        @click="gotoPersonalInformation()"
                        class="w-full inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                        :class="{ disabled: !validTimeslot === true }"
                    >
                        {{ $t('button.continue') }}
                        <svg class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                            <path
                                fill-rule="evenodd"
                                d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                <!-- personalinformation -->
                <div v-show="page == 'personalinformation'">
                    <div class="mb-3">
                        <label for="name" class="block text-xs" :class="{ 'text-red-500': errors.name }">{{ $t('fields.name') }} *</label>
                        <div class="mt-1">
                            <Field
                                type="text"
                                v-model="form.name"
                                name="name"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                :class="{ error: errors.name }"
                            />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="email" class="block text-xs" :class="{ 'text-red-500': errors.email }">{{ $t('fields.email') }} *</label>
                        <div class="mt-1">
                            <Field
                                type="text"
                                v-model="form.email"
                                name="email"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                :class="{ error: errors.email }"
                            />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="phone_number" class="block text-xs" :class="{ 'text-red-500': errors.phone_number }"
                            >{{ $t('fields.phonenumber') }} *</label
                        >
                        <div class="mt-1">
                            <Field
                                type="text"
                                v-model="form.phone_number"
                                name="phone_number"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                :class="{ error: errors.phone_number }"
                            />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="notes" class="block text-xs" :class="{ 'text-red-500': errors.notes }">{{ $t('fields.notes') }}</label>
                        <div class="mt-1">
                            <Field
                                as="textarea"
                                v-model="form.notes"
                                name="notes"
                                class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent"
                                :class="{ error: errors.notes }"
                            />
                        </div>
                    </div>

                    <div class="mb-3" v-if="settings.require_termsandconditions == true">
                        <div class="flex items-start">
                            <div class="absolute flex items-center h-5">
                                <Field
                                    type="checkbox"
                                    id="termsandconditions"
                                    name="termsandconditions"
                                    :value="true"
                                    class="form-checkbox"
                                    :class="{ error: errors.termsandconditions }"
                                />
                            </div>
                            <div class="pl-7 text-sm leading-5">
                                <label for="termsandconditions" class="text-base text-main" :class="{ 'text-error': errors.termsandconditions }">{{
                                    $t('fields.termsandconditions')
                                }}</label>
                                <modal-terms-and-conditions />
                            </div>
                        </div>
                    </div>

                    <div class="mb-3" v-if="settings.require_termsandconditions == true">
                        <div class="flex items-start">
                            <div class="absolute flex items-center h-5">
                                <Field
                                    type="checkbox"
                                    id="privacypolicy"
                                    name="privacypolicy"
                                    :value="true"
                                    class="form-checkbox"
                                    :class="{ error: errors.privacypolicy }"
                                />
                            </div>
                            <div class="pl-7 text-sm leading-5">
                                <label for="privacypolicy" class="text-base text-main" :class="{ 'text-error': errors.privacypolicy }">{{
                                    $t('fields.privacypolicy')
                                }}</label>
                                <modal-privacy-policy />
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 mb-10 relative">
                        <button
                            :disabled="saving"
                            type="submit"
                            class="w-full inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                        >
                            {{ $t('button.confirm.reservation') }}
                            <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path
                                    class="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                            <svg v-else class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    fill-rule="evenodd"
                                    d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                </div>

                <!-- confirmation -->
                <div v-if="page == 'confirmation'">
                    <div class="mt-5 text-center">
                        <h2 class="font-bold text-xl text-center">{{ $t('reservation.confirmation.title') }}</h2>
                        <p class="mt-3">{{ $t('reservation.confirmation.description') }}</p>
                    </div>

                    <div class="mt-2 grid grid-cols-2">
                        <div class="col-span-1 mr-2 text-right">{{ $t('reservation.confirmation.name') }}:</div>
                        <div class="col-span-1 ml-2">
                            {{ form.name }}
                        </div>
                    </div>

                    <div class="grid grid-cols-2">
                        <div class="col-span-1 mr-2 text-right">{{ $t('reservation.confirmation.date') }}:</div>
                        <div class="col-span-1 ml-2">
                            {{ form.date }}
                        </div>
                    </div>

                    <div class="grid grid-cols-2">
                        <div class="col-span-1 mr-2 text-right">{{ $t('reservation.confirmation.time') }}:</div>
                        <div class="col-span-1 ml-2">
                            {{ processedTimeslot(form.timeslot) }}
                        </div>
                    </div>

                    <div class="grid grid-cols-2">
                        <div class="col-span-1 mr-2 text-right">{{ $t('reservation.confirmation.quantity') }}:</div>
                        <div class="col-span-1 ml-2">
                            {{ form.quantity }}
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { Field, Form } from 'vee-validate';
import * as yup from 'yup';
import * as _notification from '../lib/notification';

// libs
import * as _api from '../lib/api';
import * as _transaction from '../lib/transaction';
import * as _state from '../lib/state';
import ItemHelper from '../mixins/item.helper.js';

// components
import ModalTermsAndConditions from '../components/ModalTermsAndConditions';
import ModalPrivacyPolicy from '../components/ModalPrivacyPolicy';

export default {
    name: 'Reservation',
    props: ['location_slug'],
    mixins: [ItemHelper],
    components: {
        Field,
        Form,
        ModalPrivacyPolicy,
        ModalTermsAndConditions,
    },
    data() {
        const schema = yup.object().shape({
            name: yup.string().required().max(255),
            email: yup.string().required().email().max(255),
            phone_number: yup.string().required().max(255),
            notes: yup.string(),
            // termsandconditions: yup.bool().required(),
            // privacypolicy: yup.bool().required(),
        });

        return {
            quantity: null,
            initialitem: null,
            initialquantity: 1,
            settings: {
                areaselection: false,
                daysupfront: 0,
                require_termsandconditions: true,
            },
            minimum_date: moment().format('YYYY-MM-DD'),
            page: 'timeslot',
            saving: false,
            tables: null,
            schema,
            timeslot: {},
            areas: [],
            timeslotsAvailableAfterStart: null,
            reservation_start_time: null,
            reservation_end_time: null,
            full_day_checked: false,
            reservation_items: [],
            timeslots: [],
            tableStillAvailable: true,
            datepicker_attributes: [
                {
                    excludeDates: null,
                },
            ],
            form: {
                date: moment().format('YYYY-MM-DD'),
                timeslot_id: null,
                quantity: null,
                table_id: null,
            },
        };
    },
    async mounted() {
        // load  initial settings
        const settings = await _api.get('settings/reservation');

        this.settings.areaselection = settings.attributes.areaselection;
        this.settings.daysupfront = settings.attributes.daysupfront;
        this.settings.require_termsandconditions = settings.attributes.require_termsandconditions;
        this.minimum_date = moment().add(this.settings.daysupfront, 'days').format('YYYY-MM-DD');

        if (this.settings.areaselection == true) {
            this.setAreas();
        } else {
            this.setTimeslots();
        }

        this.processTimeslotsAvailableAfterStart();
    },

    methods: {
        tablesAvailable(tables) {
            return tables.filter((table) => table.available == true).length;
        },

        gotoPersonalInformation() {
            if (!this.validTimeslot === true) {
                return;
            }

            this.page = 'personalinformation';
            window.scrollTo(0, 0);
        },

        combineTimeslotsAndReservationItems(timeslot = false) {
            this.reservation_items = [];
            let timeslots = [];
            let itemToPush = '';

            if (this.timeslot.attributes?.has_ending_time == true) {
                let starting_slot = this.timeslot.attributes.timeslots.filter((timeslot) => timeslot.from == this.reservation_start_time);
                let ending_slot = this.timeslot.attributes.timeslots.filter((timeslot) => timeslot.to == this.reservation_end_time);

                let startIndex = this.timeslot.attributes.timeslots.indexOf(starting_slot[0]);
                let endIndex = this.timeslot.attributes.timeslots.indexOf(ending_slot[0]);

                //get all selected timeslots
                for (let i = startIndex; i <= endIndex; i++) {
                    timeslots.push(this.timeslot.attributes.timeslots[i].slot);
                }

                // check for products when multipe timeslots options are used
                let fitting_multiple_timeslot_options = [];
                let multiple_timeslot_options = this.timeslot.attributes.reservation_items_multiple_timeslots;
                if (multiple_timeslot_options) {
                    fitting_multiple_timeslot_options = multiple_timeslot_options.filter((option) => option.quantity <= timeslots.length);
                }

                if (this.timeslot.attributes.fixed_fee == true) {
                    itemToPush = this.timeslot.attributes.reservation_item;
                } else if (fitting_multiple_timeslot_options.length) {
                    itemToPush = fitting_multiple_timeslot_options[0].item;
                } else {
                    for (let i = startIndex; i <= endIndex; i++) {
                        itemToPush = '';
                        // add item for individual timeslot

                        if (this.timeslot.attributes.timeslots[i].reservation_item) {
                            itemToPush = this.timeslot.attributes.timeslots[i].reservation_item;
                        } else if (this.form.area_id) {
                            //add item for area
                            let active_area = this.areas.filter((area) => area.id == this.form.area_id);

                            if (active_area.length && active_area[0].attributes.area_item) {
                                itemToPush = active_area[0].attributes.area_item;
                            } else if (this.timeslot.attributes.reservation_item) {
                                //global timeslot item
                                itemToPush = this.timeslot.attributes.reservation_item;
                            }
                        } else if (this.timeslot.attributes.reservation_item) {
                            //global timeslot item (duplicate code to make more readable);
                            itemToPush = this.timeslot.attributes.reservation_item;
                        }


                    }
                }

                if (itemToPush) {
                    this.reservation_items.push(itemToPush);
                }

                this.form.timeslot = timeslots;
            } else {
                this.form.timeslot = [timeslot.slot];
                timeslots.push(timeslot.slot);

                // duplicate code fragment
                if (this.form.area_id) {
                    //add item for area
                    let active_area = this.areas.filter((area) => area.id == this.form.area_id);

                    if (active_area.length && active_area[0].attributes.area_item) {
                        this.reservation_items.push(active_area[0].attributes.area_item);
                    } else if (this.timeslot.attributes.reservation_item) {
                        //global timeslot item
                        this.reservation_items.push(this.timeslot.attributes.reservation_item);
                    }
                }
            }

            this.setTables();
        },

        gotoTimeslot() {
            this.page = 'timeslot';
        },

        selectArea(area) {
            this.reservation_start_time = '';
            this.reservation_end_time = '';
            this.form.area_id = area.id;
            this.setTimeslots();
        },

        toggleSelectFullDay() {
            this.full_day_checked = !this.full_day_checked;

            if (this.full_day_checked === true) {
                this.reservation_start_time = document.querySelector('.select-start')[1].value;
                this.reservation_end_time = document.querySelector('.select-end')[document.querySelector('.select-end').length - 1].value;

                this.combineTimeslotsAndReservationItems();
            } else {
                this.reservation_start_time = null;
                this.reservation_end_time = null;
                this.form.timeslot = null;
            }
        },

        processTimeslotsAvailableAfterStart() {
            if (this.reservation_start_time) {
                let starting_slot = this.timeslot.attributes.timeslots.filter((timeslot) => timeslot.from == this.reservation_start_time);

                let startIndex = this.timeslot.attributes.timeslots.indexOf(starting_slot[0]);

                let slotsAfterStart = this.timeslot.attributes.timeslots.slice(startIndex);

                let slotsWhileAvailable = [];
                let length = slotsAfterStart.length;
                for (let i = 0; i < length; i++) {
                    if (slotsAfterStart[i].available == true) {
                        slotsWhileAvailable.push(slotsAfterStart[i]);
                    } else {
                        break;
                    }
                }

                this.timeslotsAvailableAfterStart = slotsWhileAvailable;
            } else {
                if (this.timeslot && this.timeslot.attributes && this.timeslot.attributes.timeslots) {
                    this.timeslotsAvailableAfterStart = this.timeslot.attributes?.timeslots;
                }
            }

            // if (this.timeslot && this.timeslot.attributes && this.timeslot.attributes.timeslots) {
            //     console.log(this.timeslot.attributes.timeslots, this.timeslotsAvailableAfterStart.length);

            //     if (this.timeslot.attributes?.timeslots.length == this.timeslotsAvailableAfterStart.length) {
            //         this.fullDayAvailable = true;
            //     } else {
            //         this.fullDayAvailable = false;
            //     }
            // }
        },

        processedTimeslot(timeslot) {
            let last_slot = timeslot[timeslot.length - 1].split(' ');

            return timeslot[0].split(' ')[0] + ' - ' + last_slot[last_slot.length - 1];
        },

        async setAreas() {
            this.areas = await _api.get('reservations/areas');
        },

        async setTables() {
            this.tables = await _api.post('reservations/areas/tables', {
                area_id: this.form.area_id,
                timeslots: this.form.timeslot,
                date: this.form.date,
            });
        },

        setTable(evt) {
            this.form.table_id = evt.target.value;
        },

        async setTimeslots() {
            if (this.form.date && this.form.area_id) {
                this.timeslot = await _api.post('/timeslots', {
                    date: moment(this.form.date).format('YYYY-MM-DD'),
                    function: 'reservation',
                    area_id: this.form.area_id,
                    quantity: this.form.quantity,
                });
            } else {
                this.timeslot = false;
                return;
            }

            this.reservation_start_time = null;
            this.reservation_end_time = null;

            if (!this.timeslot) {
                return;
            }

            // reset date when selected date exceeds maximum date
            if (moment(this.form.date).format('YYYY-MM-DD') > moment(this.timeslot.attributes.maximum_date).format('YYYY-MM-DD')) {
                this.form.date = '';
            }

            // reset timeslot
            this.form.timeslot = '';
            this.form.timeslot_id = null;

            // set default quantity
            if (!this.form.quantity && this.timeslot.attributes.resources_defaultvalue) {
                this.form.quantity = parseInt(this.timeslot.attributes.resources_defaultvalue);
            }
        },

        async onSubmit() {
            this.saving = true;
            let reservation_type = this.timeslot.attributes.reservation_fee_type;
            let tab_on_table = false;
            let reservationFeeOnTableTab = null;
            let reservationFeeDepositLine = null;
            let payment = false;

            if (this.timeslot.attributes.reserve_table == true) {
                await this.setTables();
                let tableStillAvailable = this.tables.data.filter((table) => table.id == this.form.table_id)[0].available == true;

                if (tableStillAvailable == false) {
                    _notification.set(this.$t('reservation.table_no_longer_available'), 'error')
                    this.gotoTimeslot();
                    tableStillAvailable = true;
                    this.form.table_id = null;
                    return;
                }
            }

            if (['product', 'deposit'].includes(reservation_type) == true && this.timeslot.attributes.reservation_fee_on_table_tab == false) {
                payment = true;
            }

            // Logic for adding products or deposit to table;
            if (this.timeslot.attributes.reserve_table == true && this.timeslot.attributes.reservation_fee_on_table_tab == true) {
                tab_on_table = true;
                reservationFeeOnTableTab = reservation_type;

                if (
                    reservationFeeOnTableTab === 'deposit' &&
                    this.timeslot.attributes.cashfunction_id &&
                    this.timeslot.attributes.cashfunction_price
                ) {
                    let quantityToAdd = 1;

                    if (this.timeslot.attributes?.fee_per_person == true) {
                        quantityToAdd = this.form.quantity;
                    }

                    reservationFeeDepositLine = {
                        id: 'reservation',
                        fixed_item: true,
                        line_is_fee: false,
                        type: 'cashinout',
                        cashfunction_id: this.timeslot.attributes.cashfunction_id,
                        description: 'Reservation',
                        quantity: quantityToAdd,
                        kitchen_groceries: [],
                        rate: this.timeslot.attributes.cashfunction_price,
                        rate_without_discount: this.timeslot.attributes.cashfunction_price,
                        total: this.timeslot.attributes.cashfunction_price,
                    };
                }
            }

            // store reservation
            const reservation_data = await _api.post('reservations', {
                type: 'webshop',
                timeslot_id: this.timeslot.id,
                area_id: this.form.area_id,
                location_id: this.location.id,
                table_id: this.form.table_id,
                date: this.form.date,
                quantity: this.form.quantity,
                timeslot: this.form.timeslot,
                email: this.form.email,
                name: this.form.name,
                phone_number: this.form.phone_number,
                notes: this.form.notes,
                payment: payment,
                has_ending_time: this.timeslot.attributes.has_ending_time,
                fixed_reservation_fee: this.timeslot.attributes.fixed_fee,
                reserve_table: this.timeslot.attributes.reserve_table,
                fee_per_person: this.timeslot.attributes.fee_per_person,
                reservation_fee_on_table_tab: reservationFeeOnTableTab,
                reservation_fee_products: this.reservation_items,
                reservation_fee_deposit_line: reservationFeeDepositLine,
            });

            //add products or deposit to cart
            if (['product', 'deposit'].includes(reservation_type) == true && tab_on_table == false) {
                _transaction.setMethod('reservation');
                _transaction.setReservationId(reservation_data.data.id);


                if (reservation_type == 'product' && this.reservation_items.length) {
                    let self = this;
                    this.reservation_items.forEach(function (elem) {
                        self.initialitem = elem;
                        self.initialquantity = 1;

                        if (self.timeslot.attributes?.fee_per_person == true) {
                            self.initialquantity = self.form.quantity;
                        }

                        self.addItem();
                    });
                } else if (reservation_type == 'deposit' && this.timeslot.attributes.cashfunction_id) {
                    let quantityToAdd = 1;

                    if (this.timeslot.attributes?.fee_per_person == true) {
                        quantityToAdd = this.form.quantity;
                    }

                    let lineToAdd = {
                        id: 'reservation',
                        fixed_item: true,
                        line_is_fee: false,
                        type: 'cashinout',
                        cashfunction_id: this.timeslot.attributes.cashfunction_id,
                        description: 'Reservation',
                        quantity: quantityToAdd,
                        kitchen_groceries: [],
                        rate: this.timeslot.attributes.cashfunction_price,
                        rate_without_discount: this.timeslot.attributes.cashfunction_price,
                        total: this.timeslot.attributes.cashfunction_price,
                    };

                    _transaction.addItem(lineToAdd, false);
                }

                this.$router.push({ name: 'checkout.basket', params: { location_slug: this.location.slug }, query: { method: 'reservation' } });
            } else if (tab_on_table == true && this.reservation_items.length && this.form.table_id) {
                console.log('add product to table at reserved time');
            }

            if (reservation_data) {
                this.page = 'confirmation';
            }

            this.saving = false;
        },
    },
    computed: {
        location() {
            return _state.get('location/getLocation');
        },

        transaction() {
            return _state.get('transaction/getTransaction');
        },

        fullDayAvailable() {
            //Only allow selectFullDay when not today
            return moment(this.form.date).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD');
        },

        validTimeslot() {
            if (this.timeslot?.attributes?.reserve_table == true && !this.form.table_id) {
                return false;
            }

            if (this.reservation_start_time && this.reservation_end_time) {
                return true;
            }

            return this.form.quantity > 0 && this.form.timeslot && this.form.date;
        },
    },
    watch: {
        reservation_start_time: function () {
            if (this.reservation_start_time && this.reservation_end_time && this.reservation_start_time !== this.reservation_end_time) {
                this.combineTimeslotsAndReservationItems();
            } else {
                this.form.timeslot = null;
                this.tables = null;
            }
        },

        reservation_end_time: function () {
            if (this.reservation_end_time && this.reservation_start_time && this.reservation_start_time !== this.reservation_end_time) {
                this.combineTimeslotsAndReservationItems();
            } else {
                this.form.timeslot = null;
                this.tables = null;
            }
        },

        timeslot: function () {
            this.timeslotsAvailableAfterStart = this.timeslot.attributes?.timeslots;
        },
    },
};
</script>
